import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { PageHeader } from '@design-system/page-header-standard';
import BazaarVoice from '@husky-x/gatsby-theme-husky/src/common/BazaarVoice';
import BodyRender from '@husky-x/gatsby-theme-husky/src/components/BodyRender';
import { Layout } from '@husky-x/gatsby-theme-husky/src/layout/Layout';
import { pageHeaderMapper } from '@husky-x/gatsby-theme-husky/src/mappers/pageHeader';
import { ContentPageData } from '@husky-x/gatsby-theme-husky/src/templates/L2ContentPage';
import { addBrandPostfixConditionally } from '@husky-x/gatsby-theme-husky/src/utils/addBrandPostfixConditionally';
import withContentstackPreview from '@husky-x/gatsby-theme-husky/src/utils/livePreview';

export const L2ContentPage: FC<ContentPageData> = ({
  data: {
    contentstackL2ContentPage,
    contentstackFooter,
    contentstackSettings,
    allContentstackItemListingGrid: { nodes: itemListingGrids },
  },
  pageContext: { uid, locale, seoTitle, brand },
}) => {
  if (typeof window === 'undefined') console.info('Building:', { uid, locale, seoTitle });

  const { seo, pageHeader, fields, body } = contentstackL2ContentPage;
  const { bazaarvoiceUrl } = contentstackSettings;

  return (
    <Layout
      {...{
        seo,
        footer: contentstackFooter,
        pageUid: uid,
        locale,
        brand,
        bazaarvoiceUrl,
      }}
    >
      {bazaarvoiceUrl ? <BazaarVoice url={bazaarvoiceUrl} /> : null}
      {!!pageHeader?.headingBlock?.headline || pageHeader?.showBreadcrumbs ? (
        <PageHeader {...pageHeaderMapper({ pageHeader, fields })} />
      ) : null}
      <BodyRender items={body} externalData={{ locale, itemListingGrids }} currentPageUid={uid} />
    </Layout>
  );
};

export const query = graphql`
  query L2ContentPage($id: String, $locale: String, $brand: [String]) {
    contentstackL2ContentPage(id: { eq: $id }) {
      uid
      fields {
        ...PageFieldsFragment
      }
      seo {
        ...SeoFragment
      }
      pageHeader: page_header {
        ...PageHeaderFragment
      }
      body {
        ...BodyFragment
      }
    }
    allContentstackItemListingGrid {
      nodes {
        ...ItemListingGridFragment
      }
    }
    contentstackInfoBar(publish_details: { locale: { eq: $locale } }, tags: { in: $brand }) {
      ...InfoBarFragment
    }
    contentstackHeader(publish_details: { locale: { eq: $locale } }, tags: { in: $brand }) {
      ...HeaderFragment
    }
    contentstackSettings(publish_details: { locale: { eq: $locale } }, tags: { in: $brand }) {
      ...SettingsFragment
    }
    contentstackFooter(publish_details: { locale: { eq: $locale } }, tags: { in: $brand }) {
      ...FooterFragment
    }
  }
`;

export default withContentstackPreview(
  L2ContentPage,
  addBrandPostfixConditionally('l2_content_page', process.env.GATSBY_CONTENTSTACK_BRAND_TAG)
);
